<template>
    <router-view v-slot="{ Component }">
    <suspense>
        <component :is="Component" />
    </suspense>
    </router-view>
</template>

<style scoped>
.eventCard {
    max-width: 250px;
    width: 100%;
}

.fullSize {
    flex: 1;
}
</style>

<script>

export default {
    name: 'Event'
}
</script>
